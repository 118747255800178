@import 'style/variables.scss';

.hiw {
  color: white;

  &__wrapper {
    background-image: url('./assets/linesbg.svg');
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: bottom center;
    background-color: #062265;
    overflow: hidden;
  }

  .col {
    min-width: 300px;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;

    &+p {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 40px;
    }
  }

  &__illustration {
    margin-bottom: 24px;
  }

  h4 {
    font-family: Switzer, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 16px;

  }


  p {
    font-family: Switzer, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
  }

}