@import "style/variables.scss";

.main-container {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 991px) {
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
      width: 100%;
      flex-basis: auto;
    }
  }

  > .row > .col {
    &:first-child {
      &:before {
        position: absolute;
        content: "";
        display: block;
        z-index: -1;
        top: -110px; // Padding Top + Nav Height
        right: 0;
        height: 100%;
        min-height: 100vh;
        height: calc(100% + 160px);
        transition: 0.4s ease width;
      }

      &.col-md-6:before {
        width: calc((100vw - 200%) / 2 + 100%);
        background: $gradient;
      }

      &.col-md-4:before {
        width: calc((100vw - 300%) / 2 + 100%);
      }

      &.col-md-3:before {
        width: calc((100vw - 400%) / 2 + 100%);
      }

      &.col-md-2:before {
        width: calc((100vw - 500%) / 2 + 100%);
      }

      &.isLoading {
        &:before {
          z-index: 1000;
          width: 100vw !important;
          right: -50vw;
        }
      }
    }
  }
}
