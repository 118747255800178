.pricing-page {
    text-align: center;
    padding: 2rem;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    .pricing-plans {
        display: flex;
        gap: 16px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .pricing-plan {
        background: white;
        overflow: hidden;
        margin-bottom: 20px;
        font-weight: 400;
        border-radius: 8px;
        padding: 24px;
        margin: 1rem 0;
        width: 340px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08),
        0px 8px 24px -4px rgba(0, 0, 0, 0.16);


        h2 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        .price {
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 16px;
            border-bottom: 1px solid #ededed;
            padding-bottom: 16px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 1.5rem;

            li {
                margin-bottom: 0.5rem;
            }
        }

        .button {
            display: inline-block;
            padding: 0.75rem 1.5rem;
            background-color: #0034A3;
            color: #fff;
            text-decoration: none;
            border-radius: 12px;
            transition: background-color 0.3s;
            align-self: stretch;

            &:hover {
                background-color: #0034A3;
            }

            &.secondary {
                background-color: #fff;
                color: #0034A3;
                border: 1px solid #0034A3;

                &:hover {
                    background-color: #0034A3;
                    color: #fff;
                }
            }

            &.ternary {
                background-color: #f5f5f5;
                color: #333;
                border: 1px solid #333;

                &:hover {
                    background-color: #eee;
                }
            }

        }
    }

    .extra {
        a {
            color: #0034A3;
            margin-top: 12px;
        }
    }
}

.checkout-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.checkout-modal__content {
    background-color: #fefefe;
    position: relative;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;

    h2 {
        margin-bottom: 24px;

        &+p {
            margin-bottom: 24px;
            color: #333;
        }
    }
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.close {
    position: absolute;
    top: 0;
    right: 12px;
    width: auto;
    display: inline-block;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    font-weight: 300;
    font-size: 36px;
    line-height: 1;
}