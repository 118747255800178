@import "style/variables.scss";

.big.box.form-preview-box {

  .box__title {
    line-height: 40px;
    margin-bottom: 16px;
  }

  .field-input.scale {
    .scale-number {
      height: 32px;
      line-height: 30px;
    }
  }

  .field-input__radio {
    margin-bottom: 8px;

    &-label {
      &:before {
        width: 14px;
        height: 14px;
      }

      &:after {
        left: 15px;
      }
    }
  }


  .mobile-only-button {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.form-builder {
  h1 {
    font-size: 32px;
    margin-bottom: 24px;
  }

  .template__explainer {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #666;
    margin-bottom: 0;
    margin-bottom: 12px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  h5 {
    margin-bottom: 16px;
  }

  #cover {
    padding: 6px;
  }

  .add-option.button {
    margin-bottom: 16px;
    font-size: 16px;
  }

  .choice-controller {
    border: none;
    border-radius: 0;
    margin-bottom: 16px;

    input {
      width: calc(100% - 56px);
      border: 1px solid #d5d5d5;
      border-radius: 12px;
    }

    .button {
      position: absolute;
      right: 0;
      width: 48px;
      height: 48px;
      min-width: 48px;
      background-color: #e3eaf9;
      border: 0;
      background-image: url("./trashcan.svg");
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;
      outline: 0;

      &:hover {
        background-color: darken(#e3eaf9, 10%);
      }
    }

  }

  &__share-form {
    margin-bottom: 8px;
    position: relative;

    .field-input {
      padding-right: 64px;
    }

    .share-button {
      position: absolute;
      bottom: 8px;
      right: 8px;
      min-width: 40px;
    }
  }

  .preview-box {
    width: 800px;
    max-width: calc(100% - 400px);
    margin: auto;
    transform: translateX(200px);
    margin-top: 48px;
    margin-bottom: 48px;

    @media screen and (max-width: 992px) and (min-width: 768px) {
      position: static;
      display: block;
      margin: auto;
      width: calc(60% - 32px);
      transform: translateX(20vw);
      margin-top: 16px;
    }


    @media (max-width: 768px) {
      width: calc(100% - 32px);
      margin: 16px;
      max-width: 100%;
      transform: none;
      margin-top: 16px;
    }


    .add-field-box {
      margin-top: 24px;
      box-shadow: 0 6px 16px 1px rgba(0, 0, 0, 0.12),
        0 2px 8px 0 rgba(0, 0, 0, 0.16);
    }

    .mobile-only-button {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  &__add-field {
    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: 100%;
      color: #222222;
    }

    .add-field {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .button {
        width: calc(50% - 12px);
      }

      .button.primary {
        border: none;

        &.disabled-true {
          opacity: 0.8;
        }

        &.disabled-true:hover {
          cursor: initial;
        }
      }
    }

    label input,
    label select {
      border-radius: 5px;
      margin-top: 5px;
      padding: 5px;
      border: 1px solid darkgray;
      border-radius: 5px;
      outline: none;
      width: 100%;
      margin-bottom: 10px;

      margin-left: 0px;
      display: block;
      font-size: 16px;
      width: 100%;
      display: block;
    }
  }

  .question-wrapper {
    width: calc(100% - 80px);
    border-right: 1px solid rgba(200, 200, 200, 1);
    padding-right: 24px;
    min-height: 80px;
    margin-bottom: 24px;

    @media (max-width: 992px) {
      width: calc(100% - 44px);
      min-height: 110px;
      padding-right: 16px;
    }

    .field-input {
      margin-bottom: 0;
    }
  }

  .feedback-form__field-actions {
    position: absolute;
    top: 0;
    left: calc(100% + 16px);
    width: 72px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: 992px) {
      width: 32px;
    }
  }

  &__edit,
  &__move-up,
  &__move-down,
  &__delete {
    background-image: url("./trashcan.svg");
    background-position: center center;
    background-size: 20px 20px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  .form-builder__move-up,
  .form-builder__move-down {
    width: 32px;
    padding: 0;
    background-image: url("./up.svg");
  }

  .form-builder__move-down {
    background-image: url("./down.svg");
  }

  .question-wrapper:first-child .form-builder__move-up {
    opacity: 0.5;
  }

  .question-wrapper:last-child .form-builder__move-down {
    opacity: 0.5;
  }

  .settings-accordion {
    position: absolute;
    top: 100px;
    bottom:170px;
    right: 24px;
    left: 24px;
    overflow: auto;
  }
}

.form-register-modal {
  .form-register-explainer {
    color: #667;
    font-weight: 300;
  }

  .form {
    padding-bottom: 0;
  }

  .tac .field-input__label {
    margin-bottom: 0;
  }

  .checkbox .field-input__explainer {
    left: 24px;

    label {
      margin-bottom: 0;
    }
  }

  .button.disabled-true {
    opacity: 0.5;
  }

  #tacs {
    width: 16px;
    margin-top: 2px;
    margin-left: 0;
  }

  .button.button.primary.disabled-false.large {
    display: none;

    &.publish {
      display: block;
    }
  }
}

.form-settings {

  vertical-align: top;
  margin-right: 16px;


  @media screen and (min-width: 769px) {
    position: fixed;
    top: 66px;
    z-index: 3;
    left: 0;
    border-radius: 0;
    border-top: 0;
    bottom: 0;
    width: 400px;
    margin-bottom: 0;

    .nav-outer & {
      top: 48px;
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    width: 350px;
    position: fixed;
  }

  @media screen and (max-width: 992px) and (min-width: 768px) {
    display: inline-block;
    width: 40%;
  }

  @media (max-width: 768px) {
    width: calc(100% - 32px);
    margin: 16px;
  }

  .meta-form__image-side {
    display: none;
  }

  .form-preview {
    padding: 16px 0 0 0;
    text-decoration: underline;
  }

  &__open-cta {
    display: none;
  }

  .mobile-only-button {
    display: none;
  }

  &__split-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 16px;

    .button,
    .link-button {
      min-width: calc(50% - 8px);
      flex: 1 1 50%;
      border-radius: 16px;

      &:hover {
        background-color: #0b5ed7;
        color: white;
      }

      .button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .float-buttons-down {
      display: none;
    }

    &__open-cta {
      display: block;
      padding: 16px;
      text-align: center;
      border-top: 1px solid #d8dcdf;
      color: $accent;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 0;
      cursor: pointer;
    }

    .accordion-closed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease;
    }

    .accordion-open {
      position: static;
      max-height: 1000px; // Adjust as needed
      transition: max-height 0.4s ease;
    }
  }
}