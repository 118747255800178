@import "style/variables.scss";

body {
  margin: 0;
  font-family: 'Switzer', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111;
  background-color: #f5f8ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Switzer', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body a,
body a:hover {
  color: #333;
}

.min-height-container {
  min-height: calc(100vh - 130px);
  background-color: #F9F9F9;

  .inverted-nav~& {
    background-color: #002A84;
    min-height: calc(100vh - 72px);
    padding-bottom: 80px;
    display: flex;
    align-items: center;

    &+footer {
      display: none;
      align-items: center;
    }
  }
}

html body * {
  font-family: 'Switzer', sans-serif;
}


hr {
  border-color: #bbb;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: calc(100% - 30px);
    width: 1220px;
  }
}

.tos-page.box {

  h1,
  h2,
  h4 {
    margin: 0.5em 0;
  }

  ul {
    padding-left: 20px;
  }
}

a {
  text-decoration: none;
}

* {
  max-width: 100vw;
}

.tos-page {
  padding: 30px;
}

.app.fixed {
  @media screen and (min-width: 768px) {
    padding-top: 66px;
    background-color: #f8f8f8;

    nav.main-nav {
      position: fixed;
      top: 0;
      width: 100%;
    }
  }
}