.why-us {
  background-color: #fff;
  text-align: center;

  &__wrapper {
    padding: 80px 0;
  }

  h2 {
    font-family: 'Switzer', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: center;
    width: 600px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 40px;
    color: #1E293B;
  }

  h2+p {
    font-family: Switzer, sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    color: #334155;
    margin-bottom: 40px;

  }

  &__reasons {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-around;
  }

  & &__reason {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Switzer';
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    background-color: #F1F5F9;
    padding: 40px;
    border-radius: 24px;
    flex: 1 1 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);

    img {
      margin-bottom: 24px;
      height: 112px;
      align-self: flex-start;
    }

    h4 {
      font-family: 'Switzer';
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0px;
      margin-bottom: 16px;
      color: #1E293B;
    }

    p {
      font-family: 'Switzer';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 16px;
      color: #334155;
    }

    a {
      margin-top: auto;
      font-family: 'Switzer';
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #0038D7;
    }
  }

  @media (max-width: 1024px) {
    &__reasons &__reason {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }

  @media (max-width: 768px) {
    &__reasons &__reason {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}