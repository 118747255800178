.use-cases {
    background-color: #F1F5F9;
    background-image: url('./assets/linesbg2.svg');
    background-size: cover;
    padding: 80px 0;

    h2 {
        font-family: Switzer, sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 40px;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex: 1 1 calc(50% - 16px);
        /* adjusted calculation for better spacing */
        border-radius: 24px;
        overflow: hidden;
        min-height: 400px;

        @media (max-width: 768px) {
            flex: 1 1 100%;
        }

        &-title a {
            color: #0034A3;
        }

        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 40px;
            background-color: white;

            h3 {
                font-family: Switzer, sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0px;
                color: #1E293B;
                margin-bottom: 24px;
            }

            p {
                font-family: Switzer, sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0px;
                color: #334155;
                min-height: 72px;
            }

            a {

                h3 & {
                    color: #0024A3;
                }

                /* Fixed spacing from content */
                display: inline-block;
                /* Better for links */
                color: #0034A3;
                padding: 8px 12px 12px 0;
            }
        }

        &-excerpt {
            * {
                margin: 0 !important;
            }
        }

        &-image {
            height: 250px;
            background-size: cover;
            background-position: center center;
        }
    }
}