@import "style/variables.scss";

.box {
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08),
    0px 8px 24px -4px rgba(0, 0, 0, 0.16);
  padding: 24px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
  border: 1px solid #ededed;

  @media (max-width: 768px) {
    padding: 16px;
  }

  &.small-shadow {
    box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.16),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }

  &__cover {
    height: 200px;
    margin-bottom: 16px;
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
  }

  &__title {
    padding: 0;
    font-weight: 300;
    margin-bottom: 24px;
    color: rgb(58, 58, 58);

    &--secondary {
      background: white;
      color: $accent;
    }

    &--plain {
      border-bottom: 1px solid #ccc;
    }

    &--small {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 400;
    }

    &--card {
      width: 400px;
      display: inline-block;
      margin-right: 16px;
    }

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .no-padding {
    padding: 0;
  }

  .box__cover {
    background-color: $accent;
  }

  &.big {
    .box__cover {
      height: auto;
      padding-top: 40%;
      margin-bottom: 24px;
      width: calc(100%);

    }

    .box__title {
      margin-bottom: 16px;
      font-family: "Switzer", sans-serif;
      font-weight: 600;
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0px;
    }
  }
}