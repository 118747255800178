.login,
.register {
    padding: 64px;
    border-radius: 32px;
    width: 550px;
    max-width: calc(100% - 30px);

    .checkbox p label {
        margin-bottom: 0;
    }

    &-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    @media(max-width: 992px) {
        padding: 32px;
    }

    h3 {
        font-size: 32px;
        font-family: 'Switzer', sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
        color: #2D2D2D;
        margin-bottom: 8px;

        &+p {
            font-size: 16px;
            font-family: 'Switzer', sans-serif;
            color: #575757;
            font-weight: 400;
            margin-bottom: 44px;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid #ededed;
        }
    }



}