.blog {

  &__header {
    background-image: url('./Article/article-header.svg');
    height: 420px;
    background-color: rgb(241, 245, 249);
    background-size: cover;
    background-position: top center;
    padding: 80px 0 160px 0;

    p.date {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }

    p.author {
      font-family: 'Switzer', 'sans-serif';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      margin-top: 16px;
      text-align: center;

    }

    h1 {
      font-family: 'Switzer', 'sans-serif';
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: center;
      max-width: 440px;
      color: rgb(30, 41, 59, )
    }

  }

  img:first-of-type {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

  main {
    margin-top: -80px;
    display: flex;
    gap: 40px;
    justify-content: center;

    .article-link {
      margin-bottom: 0;
    }

    @media(max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  .article-link {
    display: block;
    text-decoration: none;
    margin: 0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    width: 300px;
    max-width: 100%;

    .article-link__image {
      max-width: 100%;
      margin-bottom: 20px;
      background-size: cover;
      border-radius: 16px;
      padding-top: 60%;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

    &:after {
      clear: both;
      content: '';
      display: block;
    }

    &__title {
      color: #226;
      font-size: 24px;
    }

    &__author {
      font-size: 12px;
      display: block;
    }

    &__date {
      font-size: 14px;
    }

    &__label {
      background: #ddd;
      border-radius: 2em;
      font-size: 10px;
      padding: 0.2em .8em;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      margin-right: 8px;
      float: right;
    }

    &--small &__author,
    &--small &__label {
      display: none;
    }

  }
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 24px;


}