.article {

  &__wrapper {
    background-color: white;

    &.use-case {
      main {
        margin-top: -180px;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: 1.2;
      }
    }
  }

  &.container {
    width: 720px;
    max-width: 100%;
  }

  &__header {
    background-image: url('./article-header.svg');
    height: 420px;
    background-color: rgb(241, 245, 249);
    background-size: cover;
    background-position: top center;
    padding: 80px 0 160px 0;

    .use-case & {
      // background-image: none;

      &:before {
        content: '';
        position: absolute;
        // background-image: url('./page-header.svg');
        min-height: 400px;
        width: 100%;
        background-size: cover;
        background-position: center;
        left: 0;
        z-index: 0;
        right: 0;
        height: 30vw;
        max-height: 400px;

      }

      h1 {
        position: relative;
        z-index: 1;
      }

      &~* {
        z-index: 1;
        position: relative;
      }
    }

    p.date {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }

    p.author {
      font-family: 'Switzer', 'sans-serif';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      margin-top: 16px;
      text-align: center;

    }

    h1 {
      font-family: 'Switzer', 'sans-serif';
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: center;
      max-width: 440px;
      color: rgb(30, 41, 59);

      .use-case & {
        max-width: 600px;
      }
    }

  }

  &>img:first-of-type {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

  main {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-size: 16px;
    margin-top: -80px;
    padding-bottom: 40px;
    color: #334155;


    &>* {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0.75em;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 1em;
      padding-top: 1em;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;
      padding-top: 1em;
    }

    h4 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 1em;
      padding-top: 1em;
    }
  }

  // WORDPRESS STYLING

  pre {
    background-color: #f2f2f2;
    color: #222;
    padding: 12px
  }

  pre,
  pre code {
    font-family: monospace;
  }

  .is-vertical {
    flex-direction: column;
  }

  .is-content-justification-center {
    justify-content: center;
  }

  .is-layout-flex {
    display: flex
  }

  .max-650px {
    width: 650px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .max-750px {
    width: 750px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }


  .max-850px {
    width: 850px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .has-text-align-center {
    text-align: center;
  }

  li {
    margin-bottom: 1em;
  }


  @media(min-width: 1200px) {
    .extra-200-px {
      max-width: calc(100vw - 30px);
      width: calc(100% + 200px);
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .extra-300-px {
      max-width: calc(100vw - 30px);
      width: calc(100% + 200px);
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .extra-400-px {
      max-width: calc(100vw - 30px);
      width: calc(100% + 400px);
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .extra-25-percent {
      max-width: calc(100vw - 30px);
      width: calc(100% + 25%);
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .extra-50-percent {
      max-width: calc(100vw - 30px);
      width: calc(100% + 50%);
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  .align-items-center {
    align-items: center;
  }

  .wp-block-columns {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;

    }

    .wp-block-column {
      flex-grow: 1;
      flex: 1; // Ensure equal space
    }
  }

  .wp-block-quote {
    &:before {
      content: "''";
      display: inline;
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 32px;
    }

    background-color: white;
    position: relative;
    font-size: 28px;
    line-height: 36px;
    padding: 32px;
    padding-left: 38px;
    max-width: 100%;
    width: 700px;
    font-style: italic;
    border-radius: 12px;

    * {
      font-family: 'Cardo';
    }
  }

  &__cta {
    padding: 40px;
    width: 100%;
    background-color: rgb(241, 245, 249);
    background-size: cover;
    background-image: url('./article-header.svg');
    text-align: center;
    width: 720px;
    max-width: 100%;
    margin: auto;
    border-radius: 24px;
    margin-bottom: -100px;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 768px) {
      margin: 0 16px 32px 16px;
      width: auto;
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      color: #1E293B;
      margin-bottom: 16px;
    }

    &-description {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: #334155;
    }

    & &-button {
      background-color: #0038D7;
      color: white;
      min-width: 180px;
      font-size: 16px;
      height: 56px;
      display: block;
      border-radius: 32px;
      gap: 8px;
      padding-top: 16px;
      padding-right: 24px;
      padding-bottom: 16px;
      padding-left: 24px;

    }
  }
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 24px;
}