@import "style/variables.scss";

.float-buttons-down {
  @media screen and (min-width: 769px) {
    position: absolute;
    background-color: white;
  }
  bottom: 0;
  left: 24px;
  right: 24px;
  bottom: 24px;
}

.button.large.add-field-prompt {
  padding: 24px;
  width: 100%;
  background-color: rgba(227, 234, 249, 1);
  height: auto;
  border: 1px dashed;

  &:before {
    text-align: center;
    content: "+";
    display: block;
    font-size: 40px;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: 300;
  }
}

.my-forms {
  border-radius: 12px;

  .form-category {
    margin-bottom: 16px;
  }

  &>.row>.col-md-3 {
    display: flex;
    margin-bottom: 24px;

    &:first-child {
      padding-bottom: 16px;
    }

    &>* {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      flex: 1;
      display: flex;
    }
  }

  .unpublished {
    .form-card {
      .box__cover {
        opacity: 0.8;
      }
    }
  }

  .button-form {
    background-color: white;
    box-shadow: 0 0 12px;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1019607843);
    transition: 0.4s ease all;
    padding: 16px;
    margin-bottom: 16px;
    min-height: 150px;

    &:hover {
      box-shadow: 0px 4px 8px #022c8856;
    }

    .add-button {
      background: #e3eaf9;
      width: 100%;
      height: 100%;
      padding: 12px;
      padding-top: 36px;
      color: #2750a8;
      font-size: 18px;
      font-weight: 600;
      border-radius: 12px;
      text-align: center;
      background-image: url("./formbox_plus.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 40%;

      @media screen and (max-width: 768px) {
        padding-top: 56px;
      }

      &:hover {
        background: #d1dfff !important;
        background-image: url("./formbox_plus.svg") !important;
        background-repeat: no-repeat !important;
        background-position-x: center !important;
        background-position-y: 40% !important;
      }
    }
  }

  .form-url {
    width: 100%;
    height: 100%;
  }

  .box h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .form-card {
    transition: 0.3s ease all;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px #0000001a;
    border: 0;
    padding: 16px;
    width: 100%;
    margin-bottom: 0;

    &.shared {
      &:before {
        position: absolute;
        content: "SHARED";
        top: 20px;
        right: 20px;
        background: lightblue;
        border-radius: 8px;
        padding: 4px 6px;
        font-weight: bold;
        font-size: 12px;
        z-index: 1;
      }
    }

    &:hover {
      box-shadow: 0px 4px 8px #022c8856;
    }

    .box__cover {
      height: 160px;
      border-radius: 8px;
    }
  }

  .no-padding {
    padding: 0;
  }

  .share-buttons {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
  }

  .split-buttons {
    border-top: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
  }

  .form-action {
    color: rgba(39, 80, 168, 1);
    font-weight: 600;
    font-size: 16px;
    padding-top: 16px;
    line-height: 16px;

    &:hover {
      color: darken(rgba(39, 80, 168, 1), 20%);
    }
  }
}

div.small-form {
  width: 25%;
  max-width: 100%;
  display: inline-block;

  .full-button {
    width: 100%;
    padding: 0;
    margin-left: -8px;
  }
}

.my-forms-ribbon,
.my-cards-ribbon {
  background-color: #d0ddfa;
  padding: 16px;
  border-radius: 12px;
  position: relative;
  padding-right: 480px;
  overflow: hidden;
  margin-bottom: 24px;

  @media (max-width: 992px) {
    padding-right: calc(50% + 40px);
  }

  h1 {
    color: #002a84;
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  &+h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #2d2d2d;
  }

  &-spacer {
    padding-top: 16px;
  }

  p {
    color: #001b56;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0;
    margin-top: 16px;
  }

  @media (max-width: 640px) {
    padding-right: 0;

    img {
      display: none;
    }

    h1 {
      font-size: 28px;
    }

    p {
      margin-top: 0;
    }
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    @media (max-width: 992px) {
      right: 0;
      width: 50%;
    }
  }
}

.add-button.button.medium {
  padding: 0 26px;
  margin-left: 20px;
  font-weight: 400;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 12px;
  }
}

.qr-code {
  padding: 0;
  min-width: 40px;
  width: 40px;
  height: 40px;

  &.button.secondary {
    padding: 0;
    min-width: 40px;

    &:hover {
      background-color: #e3eaf9;
    }

    .button-icon {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.form-creator {
  flex: 1;
  min-width: 300px;
  padding-right: 24px;

  &.with-preview {
    max-width: 40%;
  }
}

.template-preview {
  flex: 1;
  padding-left: 24px;
}

.add-form {
  .modal-lg {
    min-width: 800px;

    @media (max-width: 768px) {
      min-width: calc(100% - 32px);
    }
  }

  .form-creator {
    flex: 1 1 40%; // Changed from flex: 1
    padding-right: 1rem;

    &.with-preview {
      width: 40%;
      border-right: 1px solid #eee;

      @media screen and (max-width:992px) {
        width: 100%;
        max-width: 100%;
        border-right: 0;
      }

      @media screen and (max-width: 768px) {
        border-right: 0;
        margin-top: 0;
      }
    }
  }

  .add-form__footer {
    display: flex;
    justify-content: space-between;
  }

  .template-preview {
    flex: 0 0 60%; // Changed from flex: 1
    padding-left: 0;
    padding-right: 24px;

    @media (min-width: 992px) and (max-width: 1620px) {

      width: 50%;
      padding-left: 0;
      margin-bottom: 0;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      border-left: 0;
      margin-top: 0;
    }

    h4 {
      margin-bottom: 1rem;
      color: var(--primary-color);
    }

    .preview-questions {
      .preview-question {
        background: #f5f5f5;
        border-radius: 8px;
        padding: 1rem;
        margin-bottom: 1rem;

        .question-type {
          text-transform: uppercase;
          font-size: 0.8rem;
          color: #666;
          margin-bottom: 0.5rem;
        }

        .question-label {
          font-weight: 500;
        }
      }
    }

    .form-preview-box {
      background: white;
      max-height: 70vh;
      padding: 1.5rem;
      overflow: auto;
      scrollbar-width: thin;
      /* Firefox */
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
      /* Firefox */

      @media screen and (max-width: 768px) {
        max-height: initial;
      }

      /* Hide default scrollbar for Chrome/Safari/Edge */
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .box__title {
        font-size: 24px;
        margin-bottom: 16px;
      }

      .field-input {
        margin-bottom: 16px;
      }

      .field-input__label {
        font-size: 14px;
      }

      .field-input,
      .field-input input,
      .field-input textarea {
        height: 32px;

        &.radio {
          height: auto;
        }

        [type="radio"] {
          height: 12px;
          min-height: 12px;
        }
      }

      .field-input__radio .field-input__radio-button+label {
        font-size: 12px !important;
        padding-left: 28px !important;
      }

      .field-input.rating {
        height: 32px;
      }

      .field-input.long {
        height: 56px;

        input,
        textarea {
          height: 56px;
        }
      }
    }

    .question-wrapper {
      position: relative;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .field-input {

      input,
      textarea,
      select {
        pointer-events: none;
        background-color: #f5f5f5;
      }

      select {
        padding: 0 8px;
        margin: 0;
        width: 100%;
        min-height: 32px;
        font-size: 14px;
        padding-right: 12px;
        margin-right: 12px;
      }
    }
  }
}

.add-form {
  .modal-body {
    display: flex;
    gap: 24px;
  }
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;

  .page-number {
    font-size: 16px;
    color: #2d2d2d;
  }

  .button {
    min-width: 100px;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}