.meta-form {
  .form {
    padding-bottom: 0;
  }
  &__image-side {
    border-radius: 8px;
    padding-top: 28%;
    width: 100%;
    background-size: cover;
    background-color: lightgray;
    background-position: center;
  }
}
