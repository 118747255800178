@import "style/variables.scss";

.main-nav {
  background: white;
  color: #111;
  position: relative;
  z-index: 1;
  display: block;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin: auto;
  border-bottom: 1px solid #eee;

  &.logged-out {
    border: none;
  }

  .container {
    max-width: calc(100% - 30px);
    width: 1220px;
    position: relative;

    @media(max-width: 992px) {
      text-align: center;

      padding-left: 70px;
    }
  }

  &.home-nav {
    background-color: #fff;
  }

  &.inverted-nav {
    background-color: #002A84;
    color: #F3F7FF;
    border: none;
    padding-bottom: 24px;

    @media (max-width: 768px) {
      .main-nav__logo {
        text-align: center;
      }
    }

    .text-logo {
      color: #F3F7FF;
    }

    .main-nav__item a {
      color: #F3F7FF;
    }

    & .current {
      border-color: transparent;
    }
  }

  &__menu {
    margin: 0;

    &.align-right {
      text-align: right;
    }

    @media(max-width: 992px) {
      padding-left: 0;
    }
  }

  &__item {
    list-style: none;
    display: inline-block;
    padding: 0 26px;
    line-height: 32px;
    margin: 16px 0;
    font-family: 'Switzer', sans-serif;
    font-weight: 400;

    @media (max-width: 768px) {
      padding: 0 10px;
    }

    a {
      font-weight: 400;
      font-size: 14px;
      color: #575757;

      a:hover {
        color: $accent;
      }
    }

    &.current {
      border-bottom: 2px solid $accent;

      a {
        color: $accent;
        font-weight: 600;
      }

    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-weight: 500;

    @media (max-width: 768px) {
      position: static;
      transform: none;
      text-align: left;
      padding: 16px
    }

    img {
      margin: 0;
      height: 32px;
      max-height: 9vw;
      border-radius: 0;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 44px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    span {
      display: none;
    }

    @media (max-width: 768px) {
      right: 0;
      margin-bottom: 0;

      .app .main-nav & {
        width: 48px;
        background-image: none !important;

        span {
          display: block;
        }
      }

      span {
        display: block;
        line-height: 32px;
        font-size: 18px;
        color: #002A84;
        font-weight: 500;
      }
    }
  }

  #nav-hidden-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 1px;
  }

  #nav-hidden-input:focus,
  #nav-hidden-input:active {
    &+.main-nav__dropdown {
      display: block;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    right: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px #0000001A;
    text-align: left;
    top: 56px;
    right: 12px;

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    .popdown-links {
      display: block;
      background-color: #FFFFFF;
      padding: 16px;
      padding-bottom: 8px;

      &+.popdown-links {
        padding-top: 8px;
        padding-bottom: 16px;
      }

      &:hover {
        background-color: #F4F4F4;
        ;
      }
    }
  }

  .get-started {
    font-weight: 600;
    font-family: 'Switzer', sans-serif;
    font-size: 14px;
    color: #F3F7FF;
  }

  &:after {
    clear: both;
    content: "";
    display: block;
  }

  .verify-account {
    position: absolute;
    top: 14px;
    right: 60px;
    font-size: 12px;
    padding: 8px 10px;
  }

  &.nav-outer {
    height: 48px;
    text-align: center;
    padding-top: 15px;

    border-bottom: 1px solid #D8DCDF;

    .main-nav__icon {
      display: none;
    }

    .main-nav__menu {
      display: none;
    }

    .main-nav__logo {
      position: static;
      margin: auto;
      transform: none;
      line-height: 16px;
      padding-top: 0;
      text-align: center;
    }

    img {
      height: 16px;
    }
  }

  &.overwrite-logo {
    height: 80px;

    .logo-img {
      height: 48px;
    }
  }
}

.logout {
  display: none;
}

.authenticated {
  .logout {
    display: block;
  }
}

.app {
  @media(max-width:768px) {
    overflow-x: hidden
  }
}

.drawer-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
  transform: translateX(110%);
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translateX(0);

  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
    cursor: pointer;
    font-weight: 400;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0 0 20px 0;

      a {
        text-decoration: none;
        color: #111;
        font-size: 18px;
      }
    }
  }
}

.drawer-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {

  .logout {
    position: absolute;
    bottom: 24px;
    font-weight: 400;
  }

  .drawer-menu,
  .drawer-overlay {
    display: block;
  }

  .drawer-menu .drawer-menu__list {
    border-top: 1px solid #ededed;
    padding-top: 16px;
  }

  .drawer-logo {
    border-radius: 0;
    width: 120px;
    margin-bottom: 16px;
  }

  .main-nav {
    justify-content: flex-start;
  }

  .main-nav__menu {
    display: none;
  }

  .main-nav__icon {
    right: 15px;
  }
}

.main-nav.no-print~footer {
  @media print {
    display: none;
  }

}