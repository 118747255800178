.form {
  padding-bottom: 1rem;

  h4 {
    margin-bottom: 0.75em;
    font-size: 24px;
    color: #3A3A3A;
    font-weight: 600;
    line-height: 36px;
  }

  &__description {
    font-family: 'Switzer', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #666;
    margin-bottom: 0;
    margin-bottom: 24px;
  }
}

.tippy-content {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid drakblue;
  display: inline-block;
}