@import "style/variables.scss";

.loader {
  .spinner-border {
    color: $accent;
  }

  &--large {
    .spinner-border {
      font-size: 7px;
      width: 80px;
      height: 80px;
    }
  }

  &--largest {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulsate 2.4s infinite;

    .spinner-border {
      font-size: 7px;
      border-width: 3px;
      width: 120px;
      height: 120px;
    }
  }


  &--full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: white;
    left: 0;
    opacity: 0.6;
    z-index: 1000;

    .spinner-border {
      font-size: 7px;
      width: 80px;
      height: 80px;
      position: absolute;
      top: calc(50% - 70px);
      left: calc(50% - 40px);
    }
  }

  
  &.full {
    opacity: 1;
  }

  &--hidden {
    animation: 0.8s hide ease forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
