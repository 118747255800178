@import "style/variables.scss";

.toaster {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 31000;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

*::selection {
  color: white !important;
  background-color: #002c88e3 !important;
}

.toast {
  animation: slideIn 0.4s ease-out forwards;

  .toaster & {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 16px;
    width: auto;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button.close {
      float: none;
    }
  }

  .btn-close {
    opacity: 0.8;
    transform: scale(0.8);
  }

  &--success &__header,
  &--success &__body {
    color: rgb(2, 109, 2);
    background-color: rgb(252, 255, 252);
    font-size: 16px;
  }

  &--error &__header,
  &--error &__body {
    color: darkred;
    background-color: rgb(255, 250, 250);
  }
}