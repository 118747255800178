@import "style/variables.scss";

input.button,
.button {
  background-color: $accent;
  color: white;
  border: none;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;

  transition: 0.3s ease background-color;


  &.disabled-true {
    opacity: 0.5;
  }

  &.round {
    border-radius: 32px;
    padding: 0 24px;
  }

  &.get-started {
    @media screen and (max-width: 768px) {
      padding: 12px 20px;
      width: 100%;
    }
  }


  &:not(&.disabled-true).primary {
    &:hover {
      background-color: darken($accent, 20%);
    }
  }

  &.secondary {
    border: none;
    color: $accent;
    background-color: #e3eaf9;

    &.disabled-true {
      color: #7885b3;
      background: lightgray;
      border-color: #7885b3;
      opacity: 1;
    }

    &:hover {
      background-color: #D1DFFF;
    }
  }

  &.ternary {
    background-color: white;
    color: $accent;
    border: 1px solid $accent;
  }

  &.text {
    background-color: white;
    color: $accent;
  }

  &.secondary-filled {
    background: rgba(227, 234, 249, 1);
    color: $accent;

    &:not(&.disabled-true).primary {
      &:hover {
        background-color: darken(rgba(227, 234, 249, 1), 20%);
        color: darken($accent, 5%);
      }
    }
  }

  &.danger {

    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(237, 235, 255);
    width: 40px;
    height: 40px;
    border-radius: 12px;

    &:hover {
      background-color: #f6f6ff;
    }
  }

  &.small {
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    padding: 4px 8px;
    min-width: 100px;
    border-radius: 12px;
  }

  &.medium {
    font-size: 16px;
    line-height: 40px;
    padding: 0 12px;
    border-radius: 12px;
  }

  &.large,
  &.full-width {
    min-width: 160px;
    height: 56px;
    border-radius: 12px;
    padding: 16px;
    gap: 8px;
    border-width: 1px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &.full-width {
    width: 100%;
    min-width: 100%;
  }

  .button-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;

    img,
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.link-button {
  display: inline-block;
}