.home-cta {

    &__cta {
        padding: 80px;
        width: 100%;
        background-color: #0038D7;
        background-size: cover;
        background-image: url('./assets/linesbg.svg');
        text-align: center;
        max-width: 100%;
        margin: auto;
        border-radius: 24px;
        margin-bottom: -180px;
        margin-top: 40px;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 768px) {
            margin: 0 16px 32px 16px;
            margin-bottom: -40px;
            padding: 40px 32px;
            width: auto;
        }

        h2 {
            font-size: 40px;
            line-height: 48px;
            font-weight: 600;
            color: #fff;
            margin-bottom: 32px;

            &+p {
                color: white;
            }
        }

        &-description {
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 24px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
            color: #fff;
        }

        & &-button#cta {
            background-color: #fff;
            color: #0038D7;
            min-width: 180px;
            font-size: 16px;
            height: 56px;
            display: block;
            border-radius: 32px;
            gap: 8px;
            padding-top: 16px;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;

            &:hover {
                background-color: #e4ebff;
            }
        }
    }
}