@import "style/variables.scss";

footer {
    color: white;
    text-align: center;
    line-height: 30px;
    background: #062265;
    border-top: 1px solid #ededed;
    padding: 60px 120px;
    padding-top: 160px;

    @media (min-width: 768px) {

        .nav-outer & {
            padding-left: 400px;
            padding-top: 60px;
            margin-top: 32px;

            .main-footer {
                display: flex;
                width: auto;

            }
        }
    }

    @media (min-width: 768px) and (max-width: 940px) {
        .nav-outer & {
            padding-left: 40vw;
        }
    }

    @media (max-width: 992px) {
        padding: 40px 32px;
    }

    img {
        height: 40px;
        max-height: 9vw;
        border-radius: 0;
    }

    .container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}

.main-footer {
    width: 100%;
    display: flex;
    text-align: left;
    padding: 0 15px;
    justify-content: space-between;

    .copy {
        span {
            color: white;
            display: inline-block;
            margin-top: 12px;
            text-decoration: underline;
        }
    }

    .links {
        &.use-case-links {
            margin-left: auto;
            margin-right: 32px;

            @media (max-width: 768px) {
                margin: 24px 0;
            }
        }

        text-align: left;

        span {
            font-size: 18px;
            margin-bottom: 16px;
            display: block;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.4);
        }

        a {
            color: white;
            font-size: 16px;
            display: block;
            line-height: 20px;
            width: fit-content;
            display: block;
            margin-right: auto;
            margin-bottom: 6px;
            font-family: Switzer, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0px;
            margin-bottom: 16px;
        }
    }
}

footer.authenticated-footer {
    background-color: white;
    color: #444;
    padding: 10px 0;
    justify-content: space-around;
    display: flex;

    .container {

        &.fixed {
            text-align: right;
        }

        text-align: center;
        display: block;
    }
}