@import url('https://fonts.cdnfonts.com/css/switzer?styles=135298,135294,135296,135299,135287');


$accent: #0034A3;
$accent-d1: #002A84;
$red: rgb(253, 27, 27);

$gradient: transparent linear-gradient(222deg, $accent 0%, #274cb1 42%, rgb(19, 29, 73) 100%) 0% 0% no-repeat padding-box;

$navHeight: 70px;

$shadow: 0 4px 8px rgba(0, 0, 0, 0.20);
$shadow-inset: 0 0 8px rgba(0, 0, 0, 0.16) inset;
